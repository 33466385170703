import React, { useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './styles/css/style.min.css';
import './styles/css/otstyles.min.css';
import 'lazysizes';

//Components
const renderLoader = () => <div className="loader"></div>;
const HomeComponentBistro = lazy(() => import('./components/HomeComponentBistro'));
const LandingComponent = lazy(() => import('./components/LandingComponent'));
const DashboardComponent = lazy(() => import('./components/DashboardComponent'));
const OrdersComponent = lazy(() => import('./components/OrdersComponent'));
const ProtectedRoute = lazy(() => import('./components/ProtectedRoute'));
const Unauthorized = lazy(() => import('./components/Unauthorized'));
const OrderFormComponent = lazy(() => import('./components/OrderFormComponent'));
const VoucherOrderFormComponent = lazy(() => import('./components/VoucherOrderFormComponent'));
const HomeOrdersComponent = lazy(() => import('./components/VoucherOrdersComponent'));
const BookingInfoComponentBistro = lazy(() => import('./components/BookingInfoComponentBistro'));
const CareersInfoComponent = lazy(() => import('./components/CareersInfoComponent'));
// const LandingPageComponent = lazy(() => import('./components/LandingPageComponent'));

function App() {
  const [user, setUser] = useState(false)

  const handleLogin = (name, value, days) => {
    setUser(true);
    var expires = "";

    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  const handleLogout = () => {
    setUser(false);
    window.location.href = "/login";
    document.cookie = 'login=; Max-Age=-99999999;';
  }

  return (
    <div>
      <Suspense fallback={renderLoader()}>
        <Router>
          {/* <Route exact path='/' component={LandingPageComponent} /> */}
          <Route exact path='/' render={() => <HomeComponentBistro showConfirmation={false} />} />
          {/* <Route exact path='/bistro' render={() => <HomeComponentBistro showConfirmation={false} />} />
          <Route exact path='/bookings' component={BookingInfoComponentBistro} />
          <Route path='/careers' component={CareersInfoComponent} />
          <Route exact path='/placehomeorder' component={VoucherOrderFormComponent} />
          <Route exact path='/placeorder' component={OrderFormComponent} />
          <Route exact path='/success' render={() => <HomeComponentBistro showConfirmation={true} />} />
          <Route exact path='/login' handleLogin={handleLogin} render={props => <LandingComponent {...props} user={user.toString()} handleLogin={handleLogin} />} />
          <ProtectedRoute exact path='/dashboard' user={user} handleLogout={handleLogout} component={DashboardComponent} />
          <ProtectedRoute exact path='/orders' user={user} handleLogout={handleLogout} component={OrdersComponent} />
          <ProtectedRoute exact path='/homeorders' user={user} handleLogout={handleLogout} component={HomeOrdersComponent} />
          <Route exact path='/unauthorized' component={Unauthorized} /> */}
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
